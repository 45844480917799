import React, {useEffect, useRef, useState} from 'react';
import classes from './CreateAsset.module.css'
import {Trans, useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useAuth from "../../../../../../Hooks/useAuth";
import Title from "../../../../../../components/Title/Title";
import TextInput from "../../../../../../components/TextInput/TextInput";
import Icon from "../../../../../../components/Icon/Icon";
import Button from "../../../../../../components/Button/Button";
import MATERIAL_TYPE from "../../../../../../lang/materialType";
import MATERIAL_TYPE_TR from "../../../../../../lang/materialTypeTr";
import RangeTextInput from "../../../../../../components/RangeTextInput/RangeTextInput";
import toast from "react-hot-toast";
import {getCollectionItems} from "../../../../../../api/web3";
import Web3 from "web3";
import {createItem, updateCollectionsAfterMintAsset, updateDB, updateOwners} from "../../../../../../api/auth";
import {connectToMetamask, mint, sleep} from "../../../../../../utils";
import * as Routes from "../../../../../../Routes/routes";
import {images} from "../../../../../../assets/images/images";
import * as RoutesName from "../../../../../../Routes/routes";
import UploadFile from "../../../UploadFile/UploadFile";
import AddMaterial from "./AddMaterial/AddMaterial";

import CountryList from 'country-list-with-dial-code-and-flag'
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import {Countries} from "../../../../../../utils/countries";
import DatePicker, {DateObject} from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import RingMaterial from "./Module/RingMaterial/RingMaterial";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import transition from "react-element-popper/animations/transition"
import {useGetDesigners} from "../../../../../../query";
import DesignerOption from "./Module/DesignerOption/DesignerOption";

const CreateAsset = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const {auth, setAuth} = useAuth();
    const {address} = useParams()
    const {name} = useParams()


    const {data, isLoading, error} = useGetDesigners()

    console.log("data designe db", data)



    const [uploading, setUploading] = useState(false);
    const [step, setStep] = useState("");


    const transactionHash = useRef("")
    const itemID = useRef("")


    const exploreURL = window.env.REACT_APP_BLOCK_EXPLORER;
    const openSeaURL = window.env.REACT_APP_OPENSEA_URL;
    const network = window.env.REACT_APP_NETWORK;


    const [openAddMateial, setOpenAddMaterial] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [upLoadFileData, setUploadFileData] = useState({
        type: "",
        name: "",
    });


    const [itemData, setItemData] = useState({
        name: {value: "", error: []},
        designer: {value: "", error: []},
        birthdate: {value: "", error: []},
        nfcTag: {value: "", error: []},
        country: {value: "", error: []},
        description: {value: "", error: []},
        imageLink: {value: "", error: []},
        mediaLink: {value: "", error: []},
    });

    /*const [value, setValue] = useState(new Date());*/
    /*const [value, setValue] = useState(new Date());*/



    const [initialMaterial, setInitialMaterial] = useState({value: "", error: []});
    const [materialValue, setMaterialValue] = useState({value: 100, error: []});
    const [material, setMaterial] = useState([]);

    const newPercentageRef = useRef(100)


    useEffect(()=>{
        /*if (auth?.imageIpfsLink) setItemData({...itemData,imageLink: { value: auth?.imageIpfsLink, error: []}})
        if (auth?.animationIpfsLink) setItemData({...itemData,mediaLink: { value: auth?.animationIpfsLink, error: []}})*/

        let newItemData = {...itemData}
        if (auth?.asset_imageIpfsLink) newItemData.imageLink = { value: auth?.asset_imageIpfsLink, error: []}
        if (auth?.asset_3DModelIpfsLink) newItemData.mediaLink = { value: auth?.asset_3DModelIpfsLink, error: []}
        setItemData(newItemData)

    },[openUploadFile])


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="items.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...itemData,
            [e.target.dataset.name]: {...itemData[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setItemData(prevState)
    }

    const isFormValid = () => {
        let inputs = {...itemData}

        const hasError = Object.values(itemData).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            console.log(key)
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="items.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setItemData(inputs);
        return !isEmpty;
    }



    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        if (!auth?.isConnected) return toast.error("Connect your wallet!")

        setUploading(true)

        const countNft = await getCollectionItems(address);

        const findMax = (countNft) => {
            let maxValue = null;
            for (let i = 0; i < countNft.length; i++) {

                const currentvalue = Web3.utils.toNumber(countNft[i].Id)

                if ( currentvalue > maxValue) {
                    maxValue = currentvalue;
                }
            }
            return maxValue;
        }
        let maxValue = findMax(countNft);

        /*console.log("maxValue", maxValue)*/

        const item = {
            name: itemData.name.value,
            nfcTag: itemData.nfcTag.value,
            designer: itemData.designer.value,
            birthdate: itemData.birthdate.value,
            country: itemData.country.value,
            description: itemData.description.value,
            image: itemData.imageLink.value,
            animation_url: itemData.mediaLink.value,
            material: material,
        }

        await createItem(item , auth.token)
            .then(async (res) => {

                setStep("CREATED")
                const mintData = {
                    cid: `ipfs://${res?.data?.data?.value?.cid}`,
                    address: auth?.account,
                    id: String((maxValue + 1)),
                    contractAddress: address,
                }

                await mint(mintData).then( async (res)=> {


                    console.log("res mint", res)

                    transactionHash.current = res?.transactionHash
                    itemID.current = mintData?.id


                    /*await updateCollectionsAfterMintAsset(address, auth?.token).then(()=>{
                        setStep("MINTED")
                    }).catch((e)=>{
                        console.log("error", e)
                    })*/

                    await sleep(2000)


                    await updateDB(auth.token).then((res)=>{
                        console.log("res", res)
                    }).catch((error)=>{
                        console.log("error", error)
                    }).finally(()=>{
                        setStep("MINTED")
                    })




                }).catch((err)=>{
                    toast.error("Failed to Mint, try again!");
                    setStep("")
                    setUploading(false)
                    console.log("err" , err)
                })

            })
            .catch((err) => {
                toast.error("Failed to upload file, try again!");
                setStep("")
                setUploading(false)
                console.log("err" , err)
            })


    };


    const backAfterMint = async () => {
        setStep("")
        setUploading(false)
        newPercentageRef.current = 100
        setMaterialValue({value: 100, error: []})
        setInitialMaterial({value: "", error: []})
        setMaterial([])
        setAuth({...auth,
            asset_imageIpfsLink: "",
            asset_3DModelIpfsLink: "",
        })
        setItemData({
            name: {value: "", error: []},
            nfcTag: {value: "", error: []},
            designer: {value: "", error: []},
            birthdate: {value: "", error: []},
            country: {value: "", error: []},
            description: {value: "", error: []},
            imageLink: {value: "", error: []},
            mediaLink: {value: "", error: []},
        })
    }



    const copyToClipboard = () => {
        navigator.clipboard.writeText(transactionHash.current)
        toast.success("Copied");
    };

    const wrapperContentHandeler = () => {
        if (step === "MINTED") return <div className={`column jc-center ai-center`}>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Created</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Minted</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>
            <div className={`width-100 row my-3`}>
                <div className={`width-33 column jc-center ai-center`}>
                    <img src={images.dorsaLogo} alt="boxes" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Dorsa</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`Item #${itemID.current} in Dorsa`}
                          onClick={()=>window.open(`${RoutesName.Assets(address, name) + itemID.current}`)}

                    />
                </div>
                <div className={`width-33 column  column jc-center ai-center ${classes.etherscan}`}>
                    <img src={images.etherscan} alt="etherscan" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Etherscan</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`Item #${itemID.current} in Etherscan`} onClick={()=>window.open(`${exploreURL}/tx/${transactionHash.current}`)}/>
                </div>
                <div className={`width-33 column  column jc-center ai-center`}>
                    <img src={images.openSea} alt="opensea" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Opensea</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`Item #${itemID.current} in Opensea`} onClick={()=>window.open(`${openSeaURL}/assets/${network}/${address}/${itemID.current}`)}/>
                </div>
            </div>
            <div className={`container row jc-center ai-center my-2`}>
                <Icon iconName="icon-copy-link-icon flex" iconClass={`ml-025 cursor-pointer hover`} onClick={() => copyToClipboard()} title={`Click to copy Transaction Hash`}/>
                <span className={`fs-0-8 mr-025`} >{transactionHash.current}</span>
            </div>

            <Button
                type="button"
                buttonClass={`${classes.backButton} cursor-pointer mt-2`}
                buttonTitle="Back"
                onClick={()=>backAfterMint()}
            />


        </div>
        if (step === "CREATED") return <div className={`column jc-center ai-center`}>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Created</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>
            <span className={`mt-1`}>Minting...</span>
        </div>
        if (step === "") return <div>Creating...</div>
    }

    const materialHandler = (val) => {

        setInitialMaterial({value: val, error: []})

    }


    const navigateToUploadPage = (type, name) => {
        setUploadFileData({
            type: type,
            name: name,
        })
        setOpenUploadFile(true)
        //navigate(Routes.UploadFile + "/" + type + "/" + name, {state: {from: location}})
    }


    const customStyles = {
        menu: (provided) => ({
            ...provided,
            border: '0.18vh solid #e7e7e7',
            padding: '1vh 0.5vw',
            direction: 'ltr',
            zIndex: 2
        }),
        multiValue: (provided) => ({
            ...provided,
            padding: '0 0.5vw',
            margin: '0.5vh 0.25vw',
            direction: 'ltr',
            zIndex: 2
        }),
    }

    const addMaterial = () => {

        if (initialMaterial.value.length <= 0) return setInitialMaterial({...initialMaterial, error: ["Select material"]})
        if (materialValue.value > newPercentageRef.current) {
            return setMaterialValue({...materialValue, error: [`The maximum percentage can be ${newPercentageRef.current}`]})
        }
        if (newPercentageRef.current === 0 ) {
            return setMaterialValue({...materialValue, error: [`The percentage of materials cannot be more than 100`]})
        }
        const newMaterialArray = [...material]

        newMaterialArray.push({
            type: initialMaterial.value.label,
            percent: materialValue.value
        })

        setMaterial(newMaterialArray)

        /*let percentage = 100*/
        /*
                console.log("material before for", material)

                const newPercentage = newMaterialArray.reduce((accumulator, currentValue) => accumulator - currentValue?.percent, percentage);

                newPercentageRef.current = newPercentage

                console.log("newPercentage", newPercentage)

               /!* for ( let i = 0; i < newMaterialArray.length ; i++) {
                    percentage -= newMaterialArray[i]?.percent
                }

                console.log("percentage", percentage)*!/


                setMaterialValue({...materialValue, value: newPercentage  })*/


    }

    useEffect(()=>{
        let percentage = 100
        const newMaterialArray = [...material]
        const newPercentage = newMaterialArray.reduce((accumulator, currentValue) => accumulator - currentValue?.percent, percentage);

        newPercentageRef.current = newPercentage

        setMaterialValue({...materialValue, value: newPercentage  })

    },[material])

    const removeMaterial = (index, percent) => {

        /*console.log("percent", percent)
        console.log("percent tpe of", typeof percent)*/

        const newMaterialArray = [...material]

        newMaterialArray.splice(index, 1)

        setMaterial(newMaterialArray)
        /*        newPercentageRef.current += parseInt(percent)

                const newMaterialValue = materialValue.value + parseInt(percent)
                setMaterialValue({value: newMaterialValue, error: [] })*/

    }

    const rangeTextInputHandler = (e) => {

        /*  console.log("e.target.value :", e.target.value)
          console.log("materialValue.value :", materialValue.value)*/

        if ("") {

        }

        setMaterialValue({ value: e.target.value, error: []})
    }

    const countryHandler = (val) => {
        setItemData({...itemData, country: { value: val.value , error: []}})
    }
    const designerHandler = (val) => {
        setItemData({...itemData, designer: { value: val.value , error: []}})
    }

    const setBirthDate = (val) => {
        setItemData({...itemData, birthdate: {value: new DateObject(val).format() , error: []}})
    }


    const options = data?.filter((f)=>!f?.hide).map(w => ({value: `${w.network_id}-${w.contract}` , label: <div className={`row jc-start ai-center`}>

            <div className={`${classes.avatar}`}
                 style={{backgroundImage: `url("${w?.imageLink}")`}}
            />
            <span className={`mr-1`}>{w.firstname} {w.lastname}</span>
        </div> }))



    return (
        <>
            <form onSubmit={(e)=>submit(e)} className={`width-80 m-auto py-5 min-height-100`}>
                <div className={``}>
                    <Title title="Create NFT"/>
                </div>
                <div className={`width-100 mt-3 row jc-between wrap`}>








                   {/* {value?.toDate?.().toString()}*/}


                    <TextInput
                        label={t('items.name')}
                        data-name="name"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phName')}
                        inputClass={`width-32 my-1`}
                        value={itemData.name.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.name.error}
                    />
                    <TextInput
                        label={t('items.nfc')}
                        data-name="nfcTag"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phNfc')}
                        inputClass={`width-32 my-1`}
                        value={itemData.nfcTag.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.nfcTag.error}
                    />
                   {/* <TextInput
                        label={t('items.designer')}
                        data-name="designer"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phDesigner')}
                        inputClass={`width-23 my-1`}
                        value={itemData.designer.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.designer.error}
                    />
*/}
                    
                    {/*<TextInput
                        label={t('items.birthday')}
                        data-name="birthday"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phBirthday')}
                        inputClass={`width-23 my-1`}
                        value={itemData.birthday.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.birthday.error}
                    />*/}

                    <div className={`width-32 my-1`}>
                        <span className={`font-weight-bold fs-01`}>{t('items.birthdate')}</span>
                        <DatePicker
                            format="MM/DD/YYYY"
                            /*plugins={[
                                <TimePicker position="top" />
                            ]}*/
                            maxDate={new Date()}
                            value={itemData.birthdate.value}
                            onChange={setBirthDate}

                            animations={[transition()]}
                            render={<InputIcon/>}
                            containerStyle={{
                                width: "100%",
                                height: "5vh",
                                marginTop: "0.5vh"
                            }}
                        />
                        {itemData?.birthdate?.error && <div className={`column fs-0-9 text-blue mt-025`}>
                            {itemData?.birthdate?.error.map((alert, index) => <span key={index} className={``}>{alert}</span>)}
                        </div>}
                    </div>

                    <TextInput
                        label={t('items.designer')}
                        data-name="designer"
                        data-type="input"
                        data-min={2}
                        select={true}
                        type="select"
                        placeholder={t('items.phDesigner')}
                        inputClass={`width-23 my-1 ${classes.selectInput}`}

                        value={{value: itemData.designer.value ,label: <DesignerOption data={data?.find( d => itemData.designer.value === d.network_id+"-"+d.contract)} withAvatar={false}/> }}

                        onchange={designerHandler}
                        alerts={itemData.designer.error}

                        options={data?.filter((f)=>!f?.hide).map(w => ({value: `${w.network_id}-${w.contract}` , label: <DesignerOption data={w} withAvatar={true}/> }))}

                        isMulti={false}
                        isRtl={true}
                        closeMenuOnSelect={true}
                        styles={customStyles}
                    />
                    <TextInput
                        label={t('items.country')}
                        data-name="country"
                        data-type="input"
                        data-min={2}
                        select={true}
                        type="text"
                        placeholder={t('items.phCountry')}
                        inputClass={`width-23 my-1 ${classes.selectInput}`}

                        value={{value: itemData.country.value ,label: itemData.country.value}}

                        onchange={countryHandler}
                        alerts={itemData.country.error}

                        options={Countries().map(w => ({value: w, label:w }))}

                        isMulti={false}
                        isRtl={true}
                        closeMenuOnSelect={true}
                        styles={customStyles}
                    />
                    <div className={`width-23`}/>
                    <div className={`width-23`}/>


                   {/* <div className={`width-100 wrap row jc-start ai-center`}>
                        {material.map( (mat , index) => <RingMaterial data={mat} key={index}/>)}
                    </div>*/}





                    <span className={`${classes.divider} width-100 mb-2 mt-4`}></span>

                    <div className={`width-100 my-2  row jc-between ai-start`}>



                        {/* <label className={`font-weight-bold fs-01`}>{t('items.material')}</label>*/}

                        <div className={`column jc-start ai-start width-33`}>
                            <TextInput
                                label={t('items.material')}
                                select={true}
                                subLabel="First, select the desired material and then specify its percentage, then add it"
                                type="text"
                                data-name="material"
                                data-type="text"
                                alerts={initialMaterial.error}
                                onchange={materialHandler}
                                value={initialMaterial.value}
                                inputClass={`width-100 ${classes.smallLead}`}
                                options={MATERIAL_TYPE.map(w => ({value: w, label: MATERIAL_TYPE_TR[w]}))}
                                isMulti={false}
                                isRtl={true}
                                closeMenuOnSelect={true}
                                styles={customStyles}
                                placeholder="Select"
                            />

                            <RangeTextInput
                                label="Percentage"
                                data-name="name"
                                data-type="input"
                                data-min={2}
                                type="range"
                                placeholder="Enter Material percentage"
                                inputClass={`width-100 my-2`}
                                value={materialValue.value}
                                onchange={(e) => rangeTextInputHandler(e)}
                                alerts={materialValue.error}
                            />


                            <Button
                                type="button"
                                buttonClass={`${classes.addButton} cursor-pointer width-40 mt-3`}
                                buttonTitle="Add Material"
                                onClick={()=>addMaterial()}
                            />
                        </div>

                        <div className={`width-35 flex jc-center ai-center height-100`}>
                            <RingMaterial data={newPercentageRef.current}/>
                        </div>
                        <div className={`width-40 flex jc-between ai-start wrap`}>
                            {material.map( (name , index) => <div key={index} className={`row jc-center ai-center rounded-8 width-47 ml-1 mb-2  py-1 ${classes.badge}`}  style={{backgroundColor: "#e7e7e7"}}>
                                <span className={`width-85 text-center px-05`}>{name.type}  ( {name.percent} % )</span>
                                <Icon
                                    iconName="icon-cancel-1 fs-03"
                                    iconClass={`cursor-pointer hover-text width-15`}
                                    onClick={()=>removeMaterial(index, name?.percent)}
                                />

                            </div>)}

                        </div>



                    </div>

                    <span className={`${classes.divider} width-100 mb-4 mt-2`}></span>

                    <TextInput
                        label={t('items.description')}
                        data-name="description"
                        data-type="input"
                        data-min={5}
                        type="text"
                        textarea={true}
                        placeholder={t('items.phDescription')}
                        subLabel={t('items.descriptionSublabel')}
                        inputClass={`width-100 my-3 ${classes.inputClass}`}
                        value={itemData.description.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.description.error}
                    />


                    <TextInput
                        label={t('items.imageLink')}
                        data-name="imageLink"
                        data-type="input"
                        data-min={10}
                        type="text"
                        placeholder={t('items.phImageLink')}
                        subLabel={t('items.imageLinkSublabel')}
                        inputClass={`width-48 my-1`}
                        value={itemData.imageLink.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.imageLink.error}
                        sideContent={<div className={`row jc-center ai-center mr-025 cursor-pointer hover-text`}
                                          onClick={() => navigateToUploadPage("image", "asset_image")}>

                            <Icon
                                iconName="icon-plus-circle fs-02"
                                iconClass={`cursor-pointer ml-025`}
                            />
                            <span>Upload File</span>
                        </div>}
                    />
                    <TextInput
                        label={t('items.mediaLink')}
                        data-name="mediaLink"
                        data-type="input"
                        data-min={10}
                        type="text"
                        placeholder={t('items.phMediaLink')}
                        subLabel={t('items.mediaLinkSublabel')}
                        inputClass={`width-48 my-1`}
                        value={itemData.mediaLink.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.mediaLink.error}
                        sideContent={<div className={`row jc-center ai-center mr-025 cursor-pointer hover-text`}
                                          onClick={() => navigateToUploadPage("3d-model", "asset_3DModel")}>

                            <Icon
                                iconName="icon-plus-circle fs-02"
                                iconClass={`cursor-pointer ml-025`}
                            />
                            <span>Upload File</span>
                        </div>}
                    />

                    {/*<div className={`column width-48`}>
                      <span className={`font-weight-bold fs-01`}>{t('items.image')}</span>
                      <span className={`${classes.subLabel} fs-0-9 text-gray mb-2`}>{t('items.imageSublabel')}</span>
                      <ImageInput
                          zoneCustomClass={classes.zoneBox}
                          title={<img src={images.image} alt="" className={`${classes.zoneLogo}`}/>}
                          onchange={(url) => setItemData({...itemData, image: {...itemData.image, value: url, error: []}})}
                      />
                  </div>

                  <div className={`column width-48`}>
                      <span className={`font-weight-bold fs-01`}>{t('items.media')}</span>
                      <span className={`${classes.subLabel} fs-0-9 text-gray mb-2`}>{t('items.mediaSublabel')}</span>
                      <ImageInput
                          zoneCustomClass={classes.zoneBox}
                          title={<img src={images.media} alt="" className={`${classes.zoneLogo}`}/>}
                          onchange={(url) => setItemData({...itemData, image: {...itemData.image, value: url, error: []}})}
                      />
                  </div>*/}


                </div>
                <div className={`width-100 mt-5`}>
                    <Button
                        type="submit"
                        buttonClass={`${classes.thisButton} cursor-pointer`}
                        buttonTitle={t('items.create')}
                    />
                </div>
            </form>
            { uploading && <div className={`${classes.wrapper} position-absolute column jc-center ai-center`}>
                <span
                    className={`font-weight-bold fs-20 text-white mb-3`}>{ step === "MINTED" ?`${itemData.name.value} with id #${itemID.current} minted successfully` :
                    "Create NFT ..."}</span>

                <div
                    className={`${classes.wrapperContent} mt-3 width-38 rounded-8 column jc-center ai-center py-3 px-2`}>
                    {step !== "MINTED" &&
                        <img src={images.dorsaLoading} className={`${classes.loading} mb-2`} alt="loading..."/>}
                    <span className={`fs-01`}>{wrapperContentHandeler()}</span>
                </div>

            </div>}

            { openUploadFile && <UploadFile type={upLoadFileData?.type} name={upLoadFileData?.name} setOpenUploadFile={setOpenUploadFile}/>}

            {/*<AddMaterial/>*/}

        </>
    );
};

export default CreateAsset;
